<template>
  <div>
    <PageDynamicBreadcrumb />

    <PageHeader title="Business Editor" />

    <BusinessForm
      type="edit"
      :business-id="$route.params.businessId"
      @submit="editBusiness"
      :submitting="submitting"
    />
  </div>
</template>

<script>
import PageHeader from "@/components/UI/PageHeader";
import BusinessForm from "@/components/BusinessForm";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import axios from "axios";

export default {
  name: "BusinessEditor",
  components: {
    PageDynamicBreadcrumb,
    PageHeader,
    BusinessForm
  },
  data() {
    return {
      submitting: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    editBusiness(business, thresholds) {
      this.submitting = true;
      if (business.id == "1" && thresholds.length > 0) {
        let updateData = [];
        thresholds.forEach(threshold => {
          updateData.push({
            id: threshold.id,
            value: threshold.value
          });
        });
        axios
          .post(
            "https://api.jumpfeed.com/businesses/medianet/thresholds/update",
            { thresholds: updateData }
          )
          .catch(() => {
            this.triggerActionOutcomeAlert("update", false);
          });
      }
      this.$store
        .dispatch("businesses/POST_REQUEST", {
          endpoint: "update",
          params: {
            id: business.id,
            name: business.name
          }
        })
        .then(() => {
          this.triggerActionOutcomeAlert("update", true);
          this.$router.push({ path: "/manage/businesses" });
        })
        .catch(() => {
          this.triggerActionOutcomeAlert("update", false);
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
